import React from 'react'
import { Link } from 'gatsby'
import styles from './covid19.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Covid19Page = () => (
   <Layout>
      <SEO title="Coronavirus Updates" />
      <div className={['page', styles.page__coronavirus].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               COVID-19 Updates
            </h1>
            <p className={['page--subheader', styles.page__subheader].join('')}>
               We are closely monitoring the outbreak of the COVID-19 pandemic
               and following advice from local + federal health officials. Here,
               we will provide the latest information related to the operations
               of our office.
            </p>
         </div>
         <div className={styles.content}>
            <hr />

            <h3>Latest Information on Scheduled Meetings:</h3>

            <div className={styles.info__outlined} role="alert">
               <strong>
                  <FontAwesomeIcon icon={faInfoCircle} /> An Update on In-Person
                  Meetings
               </strong>
               <br />
               <p>
                  We have begun gradually re-introducing in-person meetings at
                  our corporate office in Rockville, MD. If you prefer, the
                  option for teleconferencing solutions will remain in place as
                  it has over the past few months.
                  <br />
                  <br />
                  We are continually monitoring the situation. If you have any
                  questions, please{' '}
                  <Link to="/support/">contact our office</Link>. If you would
                  like to schedule a meeting with your advisor, you may do so on
                  our <Link to="/schedule/">scheduling page</Link>.
               </p>
            </div>

            <br />
            <h3>Latest Information on the Markets:</h3>
            <div className={styles.info__outlined}>
               <strong>
                  <FontAwesomeIcon icon={faInfoCircle} /> Market Updates
               </strong>
               <br />
               <p>
                  Our latest market updates can be viewed on our{' '}
                  <Link to="ctn">
                     <em>Current Trends & News</em> page
                  </Link>
                  .
               </p>
            </div>

            <br />
            <h3>Frequently Asked Questions:</h3>
            <div className={styles.info__outlined}>
               <strong>
                  <FontAwesomeIcon icon={faInfoCircle} /> What is the best way
                  to contact your office currently?
               </strong>
               <br />
               <p
                  className={styles.action}
                  dangerouslySetInnerHTML={{
                     __html: `
                        All of our team members are equipped with the technology, tools, and resources to work remotely. Although we may be contacted by phone, response times may be longer than usual as all calls are currently being handled by an auto-attendant.
                        <br/><br/>
                        For a faster response, we encourage you to&nbsp;
                        <a
                           href="#"
                           class="button button--primary button--small button--rounded"
                           onClick="LC_API.open_chat_window();return false;">
                           live chat <span>&rarr;</span>
                        </a>
                        &nbsp;with our office or <a href="tel:+13017706800" rel="noopener noreferrer">give us a call</a> and we will get back to you as soon as possible.
                     `,
                  }}></p>
            </div>

            <div className={styles.info__outlined}>
               <strong>
                  <FontAwesomeIcon icon={faInfoCircle} /> Can I drop off
                  paperwork?
               </strong>
               <br />
               <p>
                  If you need to supply documents to our office, please contact
                  your advisor in advance to determine the best possible timing
                  and method.
               </p>
            </div>
         </div>
      </div>
   </Layout>
)

export default Covid19Page
